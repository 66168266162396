import React from 'react';
import { ReactComponent as Logo } from './assets/vector/default-monochrome-white.svg';
import styled from 'styled-components';
import { useWindowSize } from './custom-hooks/useWindowSize.hook.js';
const EmailLink = styled.a`
  color: white;
  text-decoration: none;
  border-bottom: 1px solid white;
`;

const App = () => {
  const [width] = useWindowSize();

  const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(#08C7D6, #02023E);
    flex-direction: column
  `;

  const HalfContainer = styled.div`
   color: white;
   width: 100vw;
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   padding: 2rem;
   box-sizing: border-box;
   font-size: 1.5rem;
  `;

  return (
    <Container> 
      <HalfContainer>
        <Logo style={{width: width > 812 ? '40vw' : '60vw'}}/>
      </HalfContainer>
      <HalfContainer>
        <EmailLink href="mailto: contact@priffin.com"> contact@priffin.com</EmailLink>
      </HalfContainer>
    </Container>
  );
}

export default App;
