import { useEffect, useState } from 'react';

export const useWindowSize = () => {
  const isClient = typeof window === 'object';
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const getSize = () => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }
  const handleResize = () => {
    let { height, width } = getSize();
    setWidth(width);
    setHeight(height);
  }

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    if (width === 0) handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return [width, height];
}
